import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const MdxIcon = makeShortcode("MdxIcon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "its-carbons-biggest-overhaul-yet-new-layouts-new-colors-new-guidelines-and-a-whole-new-website-heres-everything-you-need-to-migrate-from-v9"
    }}>{`It’s Carbon’s biggest overhaul yet. New layouts, new colors, new guidelines, and a whole new website. Here’s everything you need to migrate from v9.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Welcome to v10</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Migration strategy</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Feedback</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "welcome-to-v10"
    }}>{`Welcome to v10`}</h2>
    <p>{`With Carbon v10 comes a complete refresh of the design system. The Carbon team worked hard to make the transition to v10 seamless, but there are breaking changes. This guide is for teams migrating from v9 to v10. If your team uses an older version of Carbon, some of this information may not apply to you.`}</p>
    <Row mdxType="Row">
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
        <ResourceCard subTitle="Looking for v9?" href="https://v9.carbondesignsystem.com/" mdxType="ResourceCard">
          <MdxIcon name="codesandbox" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
    </Row>
    <h2 {...{
      "id": "migration-strategy"
    }}>{`Migration strategy`}</h2>
    <h4 {...{
      "id": "talk-about-migration-early-and-often"
    }}>{`Talk about migration early and often.`}</h4>
    <p>{`Have a plan. Meet with all relevant stakeholders from design, OM, and engineering to kick off the migration process. Establish dedicated channels of communication for migration, and involve the broader team as needed.`}</p>
    <h4 {...{
      "id": "migrate-in-a-dedicated-branch"
    }}>{`Migrate in a dedicated branch.`}</h4>
    <p>{`This update includes substantial changes to layout and spacing. The Carbon team does not recommend upgrading piece-by-piece on the production build. Product teams should perform the migration first in a separate branch (or branches). This allows the creation of new design specs for any possible rough spots in alignment or sizing that may appear in the UI after upgrading.`}</p>
    <h4 {...{
      "id": "first-things-first"
    }}>{`First things first.`}</h4>
    <p>{`The Carbon team recommends tackling components, colors, type, spacing, and iconography first. These updates should be relatively straightforward. Aligning to the new grid will be more design-intensive, and can be tackled after the other elements.`}</p>
    <h2 {...{
      "id": "feedback"
    }}>{`Feedback`}</h2>
    <p>{`To leave feedback for the Carbon team, make suggestions for improvements, or just ask a question, you can `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/issues/new?template=feedback.md&labels=feedback&title=User+feedback+for+Migration+Overview+page"
      }}>{`open an issue`}</a>{` on GitHub.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      